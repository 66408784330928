import MessageMap from '../i18n.d';

const enUS: MessageMap = {
  SIDE_MENU: {
    HOME: 'Home',
    MOV: 'Minimum order value (MOV)',
    MOW: 'Maximum order weight (MOW)',
    DELIVERY_FEE: 'Delivery fee',
  },
  VERSION: {
    VERSION_APP: 'Version',
    SERVER: 'Server',
    RIGHTS_RESERVED: 'Anheuser-Busch Inbev S.A. All rights reserved.',
  },
  HOMEPAGE: {
    TITLE: 'Customization',
    MOV: {
      TITLE: 'Minimum order value (MOV)',
      DESCRIPTION: 'Manage the minimum value that orders must have.',
    },
    MOW: {
      TITLE: 'Maximum order weight (MOW)',
      DESCRIPTION: "Manage the maximum weight of a cart's total products.",
    },
    DELIVERY_FEE: {
      TITLE: 'Delivery fee',
      DESCRIPTION: 'Manage the value of the order delivery fee. ',
    },
  },
  MOV: {
    TITLE: 'Minimum order value',
    CANCEL: 'Cancel',
    AMOUNT: '$',
    SCHEDULE_HISTORY: {
      TITLE: 'Schedule history',
      DATE_TIME: 'Date / Time',
      USER: 'User',
      ACTION: 'Action',
      TYPE: 'MOV',
      ACTIONS: {
        CREATED: 'Scheduled',
        DELETED: 'Deleted',
        BYPASSED: 'Bypassed',
        COMPLETED: 'Completed',
      },
    },
    INPUT: {
      LABEL: 'Minimum order value',
      HINT: 'The amount will be considered in the local currency.',
      PLACEHOLDER: '0.00',
      ERROR: 'Values must be from 0.01 - 999999.99',
    },
  },
  MOW: {
    TITLE: 'Maximum Order Weight Management',
    BREADCRUMB: 'Maximum Order Weight',
    CANCEL: 'Cancel',
    AMOUNT: 'kg',
    SCHEDULE_HISTORY: {
      TITLE: 'Schedule history',
      DATE_TIME: 'Date / Time',
      USER: 'User',
      ACTION: 'Action',
      TYPE: 'MOW',
      ACTIONS: {
        CREATED: 'Scheduled',
        DELETED: 'Deleted',
        BYPASSED: 'Bypassed',
      },
    },
    INPUT: {
      LABEL: 'Maximum order weight',
      HINT: ' ',
      PLACEHOLDER: '00',
      ERROR: 'Value must be between {min}-{max}.',
    },
  },
  DELIVERY_FEE: {
    TITLE: 'Delivery fee',
  },
  COUNTRY_SELECTOR: {
    PLACEHOLDER: 'Select an option',
    LABEL: 'Country',
  },
  COUNTRIES: {
    HN: 'Honduras',
    SV: 'El Salvador',
    PA: 'Panama',
    BO: 'Bolivia',
    DO: 'Dominican Republic',
    PY: 'Paraguay',
    PE: 'Peru',
    EC: 'Ecuador',
    ZA: 'South Africa',
    CO: 'Colombia',
    MX: 'Mexico',
    AR: 'Argentina',
    BR: 'Brazil',
  },
  ZONE_TIME_UPDATE: {
    LOCAL_TIME_ZONE: 'Local time zone',
    LOCAL_TIME: 'Local time',
    NEXT_UPDATE_TIME: 'Next update time',
  },
  CURRENT_VALUE: {
    MOV: {
      VALUE: 'Current minimum order value',
      SCHEDULED: 'Scheduled minimum order value',
    },
    MOW: {
      VALUE: 'Current maximum order weight',
      SCHEDULED: 'Scheduled maximum order weight',
    },
  },
  EXCLUDE: {
    BUTTON: 'Exclude',
    OWNER_ONLY_EXCLUDE: 'Only the owner scheduler has the authority to exclude the next update.',
    MOV: {
      TITLE: 'Remove the minimum value?',
      TEXT: 'The scheduled minimal order value will be excluded permanently.',
      TOAST_SUCCESS: 'Minimal order value schedule excluded.',
      TOAST_ERROR: 'Unable to exclude minimal order value update.',
    },
    MOW: {
      TITLE: 'Remove the maximum weight?',
      TEXT: 'The scheduled maximum order weight will be excluded permanently.',
      TOAST_SUCCESS: 'Maximum order weight schedule excluded.',
      TOAST_ERROR: 'Unable to exclude maximum order weight update.',
    },
    CONFIRM: 'Remove',
    CANCEL: 'Cancel',
  },
  SCHEDULE_SAVE: {
    BUTTON: 'Save',
    MOV: {
      TITLE: 'Do you confirm the new amount?',
      TEXT: 'Verify that the new minimum order amount is correct.',
      TOAST_SUCCESS: 'New minimal order value scheduled.',
      TOAST_ERROR: 'Unable to schedule a minimal order value update.',
    },
    MOW: {
      TITLE: 'Confirm new scheduled maximum order weight?',
      TEXT: 'Please make sure the new maximum order weight is correct.',
      TOAST_SUCCESS: 'New maximum order weight scheduled.',
      TOAST_ERROR: 'Unable to schedule a maximum order weight update.',
    },
    CONFIRM: 'Confirm',
    CANCEL: 'Cancel',
  },
  SCHEDULE_BYPASS: {
    BUTTON: 'Bypass',
    MOV: {
      TITLE: 'Force updates',
      TEXT: 'This will alter the minimum order value immediately.',
      TOAST_SUCCESS: 'Minimal order value updated.',
      TOAST_ERROR: 'Unable to update minimal order value now.',
    },
    MOW: {
      TITLE: 'Force updates',
      TEXT: 'This will alter the maximum order weight immediately.',
      TOAST_SUCCESS: 'Maximum order weight updated.',
      TOAST_ERROR: 'Unable to update maximum order weight now.',
    },
    CONFIRM: 'Force',
    CANCEL: 'Cancel',
  },
  LAST_ALTERATIONS: {
    TITLE: 'Last Alterations',
    TABLE: {
      USER: 'User',
      UPDATED_IN: 'Updated in',
      MODIFICATION: 'Modification',
      FULL_LOG: 'See full log',
    },
    DIFFERENCES: {
      MIN_ORDER_VALUE: 'Minimum order value',
      MINIMUM_ORDER_VALUE: 'Minimum order value',
      MAX_ORDER_WEIGHT: 'Maximum order weight',
    },
    FULL_LOG: {
      TITLE: 'Full Log',
      OLD_JSON: 'Previous',
      NEW_JSON: 'New',
      CLOSE_BUTTON: 'Close',
    },
    EMPTY: {
      TITLE: 'Oops...',
      DESCRIPTION: "We haven't receive data from this country",
    },
  },
  PAGINATION: {
    QUANTITY_INDICATOR: 'of',
    PAGE_SIZE_OPTIONS: 'Items per page: {options}',
  },
  BUZZ: {
    ERROR: {
      TITLE: 'Oops, something went wrong!',
      DESCRIPTION: 'Currently unable to show this information. Please try again later.',
    },
  },
};

export default enUS;
