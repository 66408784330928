import MessageMap from '../i18n';

const es419: MessageMap = {
  SIDE_MENU: {
    HOME: 'Página de inicio',
    MOV: 'Valor mínimo de pedido (MOV)',
    MOW: 'Peso máximo de pedido (MOW)',
    DELIVERY_FEE: 'Tarifa de entrega',
  },
  VERSION: {
    VERSION_APP: 'Versión',
    SERVER: 'Servidor',
    RIGHTS_RESERVED: 'Anheuser-Busch InBev S.A. Todos los derechos reservados.',
  },
  HOMEPAGE: {
    TITLE: 'Configuración personalizada',
    MOV: {
      TITLE: 'Valor mínimo de pedido (MOV - {text})',
      DESCRIPTION: 'Programa el valor mínimo requerido para los pedidos.',
    },
    MOW: {
      TITLE: 'Peso máximo del pedido (MOW - {text})',
      DESCRIPTION: 'Programa el peso total máximo permitido para un carrito.',
    },
    DELIVERY_FEE: {
      TITLE: 'Tarifa de entrega',
      DESCRIPTION: 'Configura la tarifa de entrega para los pedidos.',
    },
  },
  MOV: {
    TITLE: 'Valor mínimo de pedido',
    CANCEL: 'Cancelar',
    AMOUNT: '$',
    SCHEDULE_HISTORY: {
      TITLE: 'Historial de programación',
      DATE_TIME: 'Fecha / Hora',
      USER: 'Usuario',
      ACTION: 'Acción',
      TYPE: 'MOV',
      ACTIONS: {
        CREATED: 'Programado',
        DELETED: 'Eliminado',
        BYPASSED: 'Forzado',
        COMPLETED: 'Terminado',
      },
    },
    INPUT: {
      LABEL: 'Valor mínimo de pedido',
      HINT: 'El monto se considerará en la moneda local.',
      PLACEHOLDER: '0.00',
      ERROR: 'Los valores deben estar entre {min} y {max}.',
    },
  },
  MOW: {
    TITLE: 'Gestión del peso máximo de pedido',
    BREADCRUMB: 'Peso máximo de pedido',
    CANCEL: 'Cancelar',
    AMOUNT: 'kg',
    SCHEDULE_HISTORY: {
      TITLE: 'Historial de programación',
      DATE_TIME: 'Fecha / Hora',
      USER: 'Usuario',
      ACTION: 'Acción',
      TYPE: 'MOW',
      ACTIONS: {
        CREATED: 'Programado',
        DELETED: 'Eliminado',
        BYPASSED: 'Forzado',
      },
    },
    INPUT: {
      LABEL: 'Peso máximo del pedido',
      HINT: ' ',
      PLACEHOLDER: '0',
      ERROR: 'El valor debe estar entre {min} y {max}.',
    },
  },
  DELIVERY_FEE: {
    TITLE: 'Tarifa de entrega',
  },
  COUNTRY_SELECTOR: {
    PLACEHOLDER: 'Selecciona una opción',
    LABEL: 'País',
  },
  COUNTRIES: {
    HN: 'Honduras',
    SV: 'El Salvador',
    BO: 'Bolivia',
    PA: 'Panamá',
    DO: 'República Dominicana',
    PY: 'Paraguay',
    PE: 'Perú',
    EC: 'Ecuador',
    ZA: 'Sudáfrica',
    CO: 'Colombia',
    MX: 'México',
    AR: 'Argentina',
    BR: 'Brasil',
  },
  ZONE_TIME_UPDATE: {
    LOCAL_TIME_ZONE: 'Zona horaria local',
    LOCAL_TIME: 'Hora local',
    NEXT_UPDATE_TIME: 'Próxima hora de actualización',
  },
  CURRENT_VALUE: {
    MOV: {
      VALUE: 'Valor mínimo de pedido actual',
      SCHEDULED: 'Valor mínimo de pedido programado',
    },
    MOW: {
      VALUE: 'Peso máximo de pedido actual',
      SCHEDULED: 'Peso máximo de pedido programado',
    },
  },
  EXCLUDE: {
    BUTTON: 'Eliminar',
    OWNER_ONLY_EXCLUDE: 'Solo el programador propietario puede eliminar la próxima actualización.',
    MOV: {
      TITLE: 'Eliminar el valor mínimo',
      TEXT: 'Esta acción es permanente y no se podrá deshacer.',
      TOAST_SUCCESS: 'Valor mínimo de pedido programado eliminado.',
      TOAST_ERROR: 'No se pudo eliminar el valor mínimo de pedido programado.',
    },
    MOW: {
      TITLE: 'Eliminar el peso máximo',
      TEXT: 'Deseas eliminar el peso máximo de pedido programado? Se eliminará definitivamente.',
      TOAST_SUCCESS: 'Peso máximo de pedido programado eliminado.',
      TOAST_ERROR: 'No se pudo eliminar el peso máximo de pedido programado.',
    },
    CONFIRM: 'Eliminar',
    CANCEL: 'Cancelar',
  },
  SCHEDULE_SAVE: {
    BUTTON: 'Guardar',
    MOV: {
      TITLE: '¿Confirmas el nuevo monto?',
      TEXT: 'Verifica que el nuevo monto mínimo de pedido sea correcto.',
      TOAST_SUCCESS: 'Nuevo valor mínimo de pedido programado.',
      TOAST_ERROR: 'No se pudo programar la actualización del valor mínimo.',
    },
    MOW: {
      TITLE: '¿Confirmas el nuevo peso?',
      TEXT: 'Verifica que el nuevo peso máximo de pedido programado sea correcto.',
      TOAST_SUCCESS: 'Nuevo peso máximo de pedido programado.',
      TOAST_ERROR: 'No se pudo programar la actualización del peso máximo de pedido.',
    },
    CONFIRM: 'Confirmar',
    CANCEL: 'Cancelar',
  },
  SCHEDULE_BYPASS: {
    BUTTON: 'Forzar cambio',
    MOV: {
      TITLE: 'Forzar la actualización?',
      TEXT: 'Esto cambiará el valor mínimo de pedido de inmediato.',
      TOAST_SUCCESS: 'Valor mínimo de pedido actualizado con éxito.',
      TOAST_ERROR: 'No se puede actualizar el valor mínimo de pedido en este momento.',
    },
    MOW: {
      TITLE: 'Forzar la actualización',
      TEXT: 'Esto cambiará el valor mínimo de pedido de inmediato.',
      TOAST_SUCCESS: 'Peso máximo de pedido actualizado con éxito.',
      TOAST_ERROR: 'No se pudo actualizar el peso máximo de pedido en este momento.',
    },
    CONFIRM: 'Forzar',
    CANCEL: 'Cancelar',
  },
  LAST_ALTERATIONS: {
    TITLE: 'Últimas alteraciones',
    TABLE: {
      USER: 'Usuario',
      UPDATED_IN: 'Actualizado en',
      MODIFICATION: 'Modificación',
      FULL_LOG: 'Ver registro completo',
    },
    DIFFERENCES: {
      MINIMUM_ORDER_VALUE: 'Valor mínimo para el pedido',
      MAX_ORDER_WEIGHT: 'Peso máximo para el pedido',
    },
    FULL_LOG: {
      TITLE: 'Registro completo',
      OLD_JSON: 'Anterior',
      NEW_JSON: 'Nuevo',
      CLOSE_BUTTON: 'Cerrar',
    },
    EMPTY: {
      TITLE: '¡Ups!',
      DESCRIPTION: 'No recibimos ningún dato de este país por aquí.',
    },
  },
  PAGINATION: {
    QUANTITY_INDICATOR: 'de',
    PAGE_SIZE_OPTIONS: 'Artículos por página: {options}',
  },
  BUZZ: {
    ERROR: {
      TITLE: '¡Ups! Algo salió mal.',
      DESCRIPTION:
        'Actualmente no podemos mostrar esta información. Por favor, inténtelo de nuevo más tarde.',
    },
  },
};

export default es419;
